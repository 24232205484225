<template>
  <v-card
    width="100%"
    :height="!addComment ? '241': 'auto'"
    class="card"
  >
    <div
      style="position: absolute; top: 10px; right: 10px;"
    >
      <v-btn
        v-if="canEdit"
        :tabindex="customtab"
        icon
        :class="smAndDown ? 'backbox': ''"
        @click="doEdit"
      >
        <v-icon color="#0C8CD9">
          mdi-pencil-outline
        </v-icon>
      </v-btn>
      <v-btn
        icon
        :class="smAndDown ? 'backbox ml-2': ''"
        @click="$emit('deleteFicha', index)"
      >
        <v-icon color="red">
          mdi-window-close
        </v-icon>
      </v-btn>
    </div>
    <v-card-title class="card__title d-flex justify-center align-center">
      {{ `FICHA ${index+1}` }}
    </v-card-title>
    <div class="" :class="smAndDown ? 'px-4': 'px-10'">
      <v-text-field
        id="termino"
        ref="termino"
        v-model="item.enunciado"
        placeholder="TÉRMINO (FRENTE)"
        outlined
        height="50"
        dense
        :tabindex="customtab+1"
        :readonly="canEdit"
        :error-messages="errors.enunciado ? errors.enunciado[0]: ''"
        :class="smAndDown ? 'rounded-xl': 'rounded-pill'"
        @focus="errors.enunciado=''"
      />
      <v-text-field
        v-model="item.respuesta"
        placeholder="RESPUESTA (ATRÁS)"
        outlined
        height="50"
        dense
        :tabindex="customtab+2"
        :error-messages="errors.respuesta ? errors.respuesta[0]: ''"
        :readonly="canEdit"
        :class="smAndDown ? 'rounded-xl': 'rounded-pill'"
        @focus="errors.respuesta=''"
      />
      <v-text-field
        v-if="addComment"
        v-model="item.comentario"
        placeholder="COMENTARIOS (OPCIONAL)"
        outlined
        height="50"
        dense
        ref="comment"
        :tabindex="customtab+4"
        :readonly="canEdit"
        :error-messages="errors.comentario ? errors.comentario[0]: ''"
        :class="smAndDown ? 'rounded-xl': 'rounded-pill'"
        @focus="errors.comentario=''"
      />
    </div>
    <div
      v-if="!addComment && isNewFlashcard"
      class="d-flex justify-end mt-3"
      :class="smAndDown ? 'px-4 py-2': 'px-10'"
    >
      <v-btn
        :tabindex="customtab+3"
        class="text-capitalize"
        style="margin-top: -4%;"
        text
        @click="doAddComment"
      >
        <p class="card__action pa-0 ma-0">
          + Agregar comentarios
        </p>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        comentario: '',
        created: '',
        enunciado: '',
        id: 1,
        modified: '',
        respuesta: ''
      })
    },
    errors: {
      type: Object,
      default: () => ({
        enunciado: '',
        respuesta: '',
        comentario: ''
      })
    },
    index: {
      type: Number,
      default: 0
    },
    customtab: {
      type: Number,
      default: 3
    },
    currentMazo: {
      type: Object,
      default: () => {}
    },
    fichaAutofocus: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    addComment: false,
    canEdit: true,
    loading: false
  }),
  computed: {
    ...mapState('myflashcards', ['flashcardsListSearched']),
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isNewFlashcard () {
      return this.currentMazo !== undefined ? this.index >= this.currentMazo.results.length : true
    }
  },
  methods: {
    async doEdit () {
      this.canEdit = false
      this.addComment = true
      this.$refs.termino.focus()
    },
    saveChanges () {
      this.loading = true
      window.setTimeout(() => {
        this.editFc = false
        this.loading = false
      }, 1000)
    },
    setCommentTrue () {
      this.addComment = true
    },
    async doAddComment () {
      await this.setCommentTrue()
      this.$refs.comment.focus()
      // if (this.$route.name === 'CreateMazo') {
      // }
    }
  },
  async mounted () {
    if (this.$route.name !== 'UpdateMazo') {
      this.canEdit = false
    }
    if (this.item.enunciado === '' && this.item.respuesta === '' && this.item.comentario === '') {
      this.canEdit = false
    }
    if (this.fichaAutofocus) {
      await this.$refs.termino.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px;
  &__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #0583DA;
  }
  &__action {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #0583DA;
    cursor: pointer;
  }
  &__save {
    background: linear-gradient(270deg, #0480DA 0%, #1FC1D0 100%);
    border-radius: 40px;
    margin-top: -20px;
  }
}
.backbox {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
</style>
